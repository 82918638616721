export const types = {
	login: "[Auth] Login",
	logout: "[Auth] Logout",
	/* *********************************************** */
	uiSetError: "[UI] Set Error",
	uiRemoveError: "[UI] Remove Error",
	/* *********************************************** */
	uiStartLoading: "[UI] Start loading",
	uiFinishLoading: "[UI] Finish loading",
	/* *********************************************** */
	REQUEST_SESSION_LOADING: "REQUEST_SESSION_LOADING",
	REQUEST_SESSION_SUCCESS: "REQUEST_SESSION_SUCCESS",
	REQUEST_SESSION_FAILURE: "REQUEST_SESSION_FAILURE",
	/* *********************************************** */
	BALANCE_OPEN_MODAL: "BALANCE_OPEN_MODAL",
	/* *********************************************** */
	TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
	/* *********************************************** */
	REQUEST_NOTIFICATIONS_LOADING: "REQUEST_NOTIFICATIONS_LOADING",
	REQUEST_NOTIFICATIONS_SUCCESS: "REQUEST_NOTIFICATIONS_SUCCESS",
	REQUEST_NOTIFICATIONS_FAILURE: "REQUEST_NOTIFICATIONS_FAILURE",
	/* *********************************************** */
	REQUEST_MENU_DATA_LOADING: "REQUEST_MENU_DATA_LOADING",
	REQUEST_MENU_DATA_SUCCESS: "REQUEST_MENU_DATA_SUCCESS",
	REQUEST_MENU_DATA_FAILURE: "REQUEST_MENU_DATA_FAILURE",
	/* *********************************************** */
	SELECTED_NODES: "SELECTED_NODES",
	/* *********************************************** */
	REQUEST_ICONS_PROCESS_LOADING: "REQUEST_ICONS_PROCESS_LOADING",
	REQUEST_ICONS_PROCESS_SUCCESS: "REQUEST_ICONS_PROCESS_SUCCESS",
	REQUEST_ICONS_PROCESS_FAILURE: "REQUEST_ICONS_PROCESS_FAILURE",
	/* *********************************************** */
	SELECTED_NODE_PROCESSES_LOADING: "SELECTED_NODE_PROCESSES_LOADING",
	SELECTED_NODE_PROCESSES_SUCCESS: "SELECTED_NODE_PROCESSES_SUCCESS",
	SELECTED_NODE_PROCESSES_FAILURE: "SELECTED_NODE_PROCESSES_FAILURE",
	/* *********************************************** */
	HIDE_TREE_MENU_DATA: "HIDE_TREE_MENU_DATA",
	/* *********************************************** */
	OPEN_PROFILE_MODAL: "OPEN_PROFILE_MODAL",
	/* *********************************************** */
	OPEN_NOTIFICATION_MODAL: "OPEN_NOTIFICATION_MODAL",
	/* *********************************************** */
	SET_USER_CONNECTIONS: "SET_USER_CONNECTIONS",
	/* *********************************************** */
	UPDATE_MENU_BY_FAVORITES_SELECT: "UPDATE_MENU_BY_FAVORITES_SELECT",
	/* *********************************************** */
	REQUEST_FAVORITES_LOADING: "REQUEST_FAVORITES_LOADING",
	REQUEST_FAVORITES_SUCCESS: "REQUEST_FAVORITES_SUCCESS",
	REQUEST_FAVORITES_FAILURE: "REQUEST_FAVORITES_FAILURE",
	UPDATE_FAVORITES_LIST: "UPDATE_FAVORITES_LIST",
	/* *********************************************** */
	REQUEST_LAST_USED_LOADING: "REQUEST_LAST_USED_LOADING",
	REQUEST_LAST_USED_SUCCESS: "REQUEST_LAST_USED_SUCCESS",
	REQUEST_LAST_USED_FAILURE: "REQUEST_LAST_USED_FAILURE",
	/* *********************************************** */
	SET_DATA_LAST_USED: "SET_DATA_LAST_USED",
	/* *********************************************** */
	SELECT_NEW_FOR_TAB: "SELECT_NEW_FOR_TAB",
	/* *********************************************** */
	// SET_USER_PICTURE: "SET_USER_PICTURE",
	/* *********************************************** */
	SET_NOTIFICATIONS_COUNT: "SET_NOTIFICATIONS_COUNT",
};
