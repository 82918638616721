/* eslint-disable no-duplicate-case */
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import jwt_decode from "jwt-decode";
import "./notificationTable.css";
import { getUrl } from "../../actions/getUrl";
import { createNodeTab } from "../../helpers/sidebar";
import { selectNewNodeForTab } from "../../actions/ui";
import { useDispatch } from "react-redux";

export const NotificationTable = ({ jsonData }) => {
	const [notificationsData, setNotificationsData] = useState([]);
	const [expandedRows, setExpandedRows] = useState(null);
	const [loading, setLoading] = useState(true);
	
	// Hook para manejar el modal del form 
	const [contentModal, setContentModal] = useState(null);
	const [showMenuModal, setShowMenuModal] = useState(false);
	const [headerTitle, setheaderTitle] = useState('');
	const dispatch = useDispatch();

	// Hook para manejar el modal del mensaje de rechazado
	const [rejectDialogVisible, setRejectDialogVisible] = useState(false);
	const [messageReject, setMessageReject] = useState("");
	const [selectedRow, setSelectedRow] = useState(null);

	// Referencia para el componente Toast (notificaciones)
	const toast = useRef(null);

	// Referencia para desabilitar botones de acciones y pintar las filas
	const [disabledButtons, setDisabledButtons] = useState({});
	const [rejectedRows, setRejectedRows] = useState({});
	const [acceptedRows, setAcceptedRows] = useState({});

	useEffect(() => {
		setNotificationsData([...jsonData]); 
        setLoading(false); 
    }, [...jsonData]);

	const formatFloat = (value) => {
		return new Intl.NumberFormat("en-EN", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		  }).format(value);
	};

	const formatDate = (value) => {
		if (!value) return ""; 
  		const date = new Date(value.replace(" ", "T"));
  		return isNaN(date) ? "Fecha inválida" : date.toLocaleDateString("es-ES",{
			day: '2-digit',
			month: '2-digit',
			year: 'numeric'
		  }); 
	};

	//Ejecuta la opcion del boton
	const executeOption = (option, data) => {
		// Según el tipo de operación, se ejecutan diferentes acciones
		switch (option) {
			case "envia_mail":
			case "autorizar":
			case "rechazar":
				callNotifAction(data, option);
				break;
			case "marcar_leido":
				callNotifAction(data, option);
				break;
			case "imprimir":
				callNotifPrint(data, option);
				break;
			case "visualizar":
			case "nuevo":
				data.operacion=option;
				callProccesA(data);
				break;
			default:
				break;
		}
	}

	//Llama a la api accion del boton con los datos segun corresponda
	const callNotifAction = (rowdata, action) => {
		try {
			let notifAction = {
				data: rowdata,
			};
			const { url_be_form } = JSON.parse( localStorage.getItem("user-data-url") );
			const myToken = localStorage.getItem("token");
			fetch(`${url_be_form}/Notifications/${action}`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${myToken}`,
				"Access-Control-Allow-Credentials": true,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(notifAction),
			})
			.then((res) => res.json())
			.then((resp) => {
				// Si la respuesta NO tiene error, deshabilita el botón y pinta la row
				if (resp.error === "") {
					const buttonKey = `${rowdata.id}_${action}`;
					setDisabledButtons(prev => ({ ...prev, [buttonKey]: true }));
					setAcceptedRows((prev) => ({ ...prev, [rowdata.id]: true })); //pinta de color verde
					if (action === "autorizar"){
						const buttonKey = `${rowdata.id}_rechazar`;
						setDisabledButtons(prev => ({ ...prev, [buttonKey]: true })); //deshabilita rechazar
						
					} 
				} 
				toast.current.show({
				severity: resp.error === "" ? "info" : "error",
				summary:
					resp.error === ""
					? resp.mensaje 
					: "Error " + resp.error,
				detail: rowdata.proceso,
				life: resp.error === "" ? 2000: 5000,
				});
			})
			.catch(error => {
				console.error("Error en la petición:", error.message);
				toast.current.show({
					severity: "error",
					summary: "Error en la solicitud " + action,
					detail: error.message,
					life: 5000,
				});
			});
		
		} catch (error) {
			console.log("ERROR: ", error);
		}
	}

	//Llama a la api de impresion que devuelve el pdf
	const callNotifPrint = (rowdata, option) => {
		try {
			let notifPrint = {
				data: rowdata
			};
			const { url_be_form } = JSON.parse( localStorage.getItem("user-data-url") );
			const myToken = localStorage.getItem("token");
			fetch(`${url_be_form}/Notifications/${option}`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${myToken}`,
				"Access-Control-Allow-Credentials": true,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(notifPrint),
			})
			.then(response => response.blob())  // Procesar la respuesta como Blob
			.then(blob => {
				const url = URL.createObjectURL(blob); // Creamos una URL temporal para el Blob
				//window.open(url, "_blank"); // Abre el PDF en una nueva pestaña
				 
				const newWindow = window.open(url, "_blank"); // Abrimos en una nueva ventana
				if (!newWindow) {
					// Si el navegador bloquea la ventana emergente, mostramos una alerta
					alert("Por favor, permite ventanas emergentes para ver el PDF.");
				}; /*else {
					newWindow.onload = () => {
						newWindow.print(); // Muestra el cuadro de dialogo de la impresion automáticamente
					};
				}*/ 
			})
			.catch(error => {
				console.error("Error al generar el PDF:", error.message);
				toast.current.show({
					severity: "error",
					summary: "Error al generar el PDF",
					detail: error.message,
					life: 3000,
				});
			});
		} catch (error) {
			console.log("ERROR: ", error);
		}
	}
	
	//Llama a aLCHEMY para el caso de nuevo y visualizar
	const callProccesA = async (data) => {
		try {
			const processTitle = data.nombre;
			const { url_be_form, url_fe_form } = JSON.parse( localStorage.getItem("user-data-url") );
			const { company, country } = JSON.parse( localStorage.getItem("user-session") );
			const token = localStorage.getItem("token");
			const lang = localStorage.getItem("language");
			const { user, conn } = jwt_decode(token); 
			const operacion = data.operacion;
			var data_values = {}
			const body = {
				PROCESO: data.operacion==="nuevo" ? data.proceso : data.formulario,
				URL_FRONT: url_fe_form,
				URL_BE: url_be_form,
				TIPO_EJECUCION: "F", //data.tipo_ejecucion,
				OPERACION: operacion,
				EMPRESA: company, //va en el token
				PAIS: country, //va en el token
				USUARIO: user, //va en el token
				conn: conn, //va en el token
				IDIOMA: lang,
				FE_AUTO: "N",
				CLAVE: {
					"nro_trans": data.nro_trans
				},
				DATA: data_values,
			};
			//console.log("BODY: ", body);
			const urlDomain = JSON.parse(localStorage.getItem("user-data-url"));
			const domain = urlDomain.url_be_menu; 
			const uri = await getUrl(domain, "postForms", "");
			const resp = await fetch(uri, {
				method: "POST",
				headers: {
					Authorization: `bearer ${token}`,
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify(body),
			});
			if (resp.status === 201) {
				const response = await resp.json();
				if (data.operacion==="nuevo"){ //abre pestaña
					data.nodo = data.id;
					const newNodeA = createNodeTab(
					data,
					<iframe
						id={"frame-" + data.id}
						title={data.proceso}
						frameBorder="0"
						width="100%"
						height="100%"
						onLoad={() => {}}
						src={response.url_form}
					></iframe>,
					'A',
					data.tipo_ejecucion,
					);
					dispatch(selectNewNodeForTab(newNodeA));
				} else { //abre modal
					setheaderTitle(processTitle); 
					setContentModal(response.url_form);
					setShowMenuModal(true);
				}
				
			} else {
				console.log("ERROR: ", "BAD STATUS");
			}

		} catch (error) {
			console.log("ERROR: ", error);
		}
	};	

	//Maneja los botones de cada fila
	const onClicRow = (element, data) => {
		const { proceso, tipo_proceso, id, nombre,
			crear, autorizar, rechazar, visualizar, 
			imprimir, envia_mail, operacion, marcar_leido, nodo, } = data;
		const myData = {
			...element,
			proceso, tipo_proceso, processId: id, nombre,
			crear, autorizar, rechazar, visualizar,
			imprimir, envia_mail, operacion, marcar_leido, nodo
		};

		const buttons = [
			{
				id: 1,
				onClick: () => executeOption("imprimir", myData),
				tooltip: "Imprimir",
				icon: "fas fa-print",
				showButton: imprimir === "S",
				severity:"info"
			},
			{
				id: 2,
				onClick: () => executeOption("visualizar", myData),
				tooltip: "Visualizar",
				icon: "fas fa-eye",
				showButton: visualizar === "S",
			},
			{
				id: 3,
				onClick: () => executeOption("autorizar", myData),
				tooltip: "Autorizar",
				icon: "far fa-check-circle",
				showButton: autorizar === "S",
				severity: "success",
				actionType: "autorizar"
			},
			{
				id: 4,
				//onClick: () => executeOption("rechazar", myData),
				onClick: () => openRejectDialog(myData),
				tooltip: "Rechazar",
				icon: "fas fa-times-circle",
				showButton: rechazar === "S",
				severity:"danger",
				actionType: "rechazar"
			},
			{
				id: 5,
				onClick: () => executeOption("envia_mail", myData),
				tooltip: "Enviar mail",
				icon: "fas fa-envelope",
				showButton: envia_mail === "S",
				severity: "warning",
				actionType: "envia_mail"
			},
			{
				id: 6,
				onClick: () => executeOption("nuevo", myData),
				tooltip: "Nuevo",
				icon: "far fa-file",
				showButton: crear === "S",
				actionType: "nuevo"
			},
			{
				id: 7,
				onClick: () => executeOption("marcar_leido", myData),
				tooltip: "Marcar como Leído",
				icon: "far fa-envelope-open",
				showButton: marcar_leido === "S",
				severity: "secondary",
				actionType: "marcar_leido"
			},
		];

		return (
			<div style={{ display: "flex", justifyContent: "center", gap: "8px"}}>
				{buttons.map(({ id, tooltip, icon, onClick, showButton, severity, actionType }) => 
					showButton ? (
					<div key={id} className="button--row--container">
						<Button
							type="button"
							className="p-button-rounded p-button-icon-only"
							onClick={onClick}
							tooltip={tooltip} 
							severity={severity}
							tooltipOptions={{
								position: "bottom",
								mouseTrack: true,
								mouseTrackTop: 15,
							}}
							disabled={disabledButtons[`${myData.id}_${actionType}`]}
						>
							<i className={icon}></i>
						</Button>
					</div>
				):null )}
			</div>
		);
	};

	//Setea el estilo del contenido las columnas segun el tipo de dato
	const styleColumn = (rowData, column) => {
		const { name, type } = column;
		const value = rowData[name];
		switch (type) {
			case "float":
				return <span className="p-column-right-aligned" draggable="false">{formatFloat(value)}</span>;
				//return formatFloat(value); 
			case "integer":
				return value;
			case "date":
				return formatDate(value);
			case "hide":
				return "";
			default:
				return value;
		}
	};

	//Arma las columnas dinamicas
	const dynamicColumns = (columns) => {
		return columns
        .filter((column) => column.type !== "hide") //Filtra las columnas ocultas
        .map((column) => (
            <Column
                key={column.name}
                field={column.name}
                header={column.header}
				//headerStyle={column.type === 'float' || column.type === 'integer' ? { textAlign: 'center' } : {}}
                body={(row) => styleColumn(row, column)}
                sortable
                //filter //por ahora no agrego filtro porque ocupa mucho lugar
            />
        ));
	};

	//Define el contenido de las filas expandidas
	const rowExpansionTemplate = (data) => {
		const columns = data.columns;
		return (
			<div className="orders-subtable">
				<DataTable value={data.pendientes} 
				id = {data.id}
				rowClassName={(rowData) => rejectedRows[rowData.id] 
					? "rejected-row" : acceptedRows[rowData.id]
					? "accepted-row"
					: ""}
				>
					{dynamicColumns(columns)}
					<Column
						header="Acciones" 
						style={{ textAlign: "center", width: "100px" }}
						body={(elem) => onClicRow(elem, data)}
					></Column>					
				</DataTable>
			</div>
		);
	};

	// Este es el modal del form de alchemy
	const menuModal = (
		<Dialog
			className="custom-dialog-form"
			header={headerTitle}
			visible={showMenuModal}
			style={{ width: "70vw", maxWidth: "90vw", minWidth: "50vw", height: '50vw', minHeight: '25vw', padding:'0.1rem'}}
			onHide={() => setShowMenuModal(false)}
		>
			<iframe
				title="ifm"
				src={contentModal}
				style={{ width: '100%', height: '99%', border: 'none'}}
			/>
		</Dialog>
	);
	// ----------------------------------

	//manejo de dialogo de rechazo
	const openRejectDialog = (rowData) => {
		setSelectedRow(rowData);
		setRejectDialogVisible(true);
	  };
	
	const handleReject = () => {
		if (!messageReject.trim()) return;
		setRejectDialogVisible(false);
		const action = "rechazar";
		try {
			let notifAction = {
				data: selectedRow,
				message: messageReject, //solo para rechazar
			};
			
			const { url_be_form } = JSON.parse( localStorage.getItem("user-data-url") );
			const myToken = localStorage.getItem("token");
			fetch(`${url_be_form}/Notifications/${action}`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${myToken}`,
				"Access-Control-Allow-Credentials": true,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(notifAction),
			})
			.then((res) => res.json())
			.then((resp) => {
				// Si la respuesta NO tiene error, deshabilita el botón y pinta la row
				if (resp.error === "") {
					const buttonKey = `${selectedRow.id}_${action}`;
					setDisabledButtons(prev => ({ ...prev, [buttonKey]: true })); //deshabilita rechazar
					const buttonKeya = `${selectedRow.id}_autorizar`;
					setDisabledButtons(prev => ({ ...prev, [buttonKeya]: true })); //deshabilita autorizar
					setRejectedRows((prev) => ({ ...prev, [selectedRow.id]: true })); //pinta la row 
				} 
				toast.current.show({
				severity: resp.error === "" ? "info" : "error",
				summary:
					resp.error === ""
					? resp.mensaje 
					: "Error " + resp.error,
				detail: selectedRow.proceso,
				life: resp.error === "" ? 2000: 5000,
				});
				
			})
			.catch(error => {
				console.error("Error en la petición:", error.message);
				toast.current.show({
					severity: "error",
					summary: "Error en la solicitud " + action,
					detail: error.message,
					life: 5000,
				});
			});
		} catch (error) {
			console.log("ERROR: ", error);
		}

		setMessageReject(""); // Limpiar el campo
	  };

	//Cuadro de dialogo para ingresar el motivo de rechazo
	const motivoRechazoModal = (
		<Dialog
		  header="Motivo de Rechazo"
		  visible={rejectDialogVisible}
		  style={{ width: "35vw" }}
		  onHide={() => setRejectDialogVisible(false)}
		>
		  <div className="p-field">
			<InputText
			  value={messageReject}
			  placeholder="Ingrese el texto del mensaje"
			  style={{ width: "100%", fontSize:"small" }}
			  onChange={(e) => setMessageReject(e.target.value)}
			/>
		  </div>
		  <div className="p-dialog-footer">
			<Button
			  label="Cancelar"
			  icon="pi pi-times"
			  onClick={() => {
				setMessageReject("");
				setRejectDialogVisible(false);
			  }}
			  className="p-button-text"
			/>
			<Button
			  label="Aceptar"
			  icon="pi pi-check"
			  onClick={handleReject}
			  className="p-button-primary"
			  disabled={!messageReject.trim()}
			/>
		  </div>
		</Dialog>
	  );	

	  
	return (
		<div className="datatable-rowexpansion-demo"
			 style={{ border: "2px solid #a6d5fa", borderRadius: "4px", padding: "4px"}}>
			<div className="card">
			{loading ? ( 
                    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
                        <ProgressSpinner />
                    </div>
                ) : ( 
				<DataTable
					value={notificationsData}
					expandedRows={expandedRows}
					onRowToggle={(e) => setExpandedRows(e.data)}
					rowExpansionTemplate={rowExpansionTemplate}
					dataKey="id"
					loading={loading}
					style={{ fontWeight: "bold" }}
				>
					<Column expander style={{ width: "3em" }} />
					<Column field="id" header="id" style={{ display: "none" }}></Column>
					<Column field="nombre" header="Proceso" ></Column>
					<Column field="cantidad" header="Cantidad"></Column>
				</DataTable>
				)}
			</div>
			{menuModal}
			{motivoRechazoModal}
			<Toast ref={toast} position="center" />

			{/* Estilos para cambiar el color de las filas rechazadas */}
			<style>{`
				.rejected-row {
				background-color: #ffcdd2 !important; /* Rojo suave - Rechazado */
				}
				.accepted-row {
				background-color: #c8e6c9 !important; /* Verde suave - Aprobado */
				}
		`	}</style>
		
		</div>
	);
};
