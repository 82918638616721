import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import { Base64 } from "js-base64";
import { Card } from "primereact/card";
import { getUrl } from "../../actions/getUrl";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./recoverPassword.css";
import { FormTitle } from "../../components/formTitle/formTitle";
import { FormInputText } from "../../common/formInputText/formInputText";

export const RecoverPassword = () => {
	const history = useHistory();

	const [userData, setUserData] = useState(null);

	const defaultValues = { email: "" };

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({ defaultValues });

	const header = <div className="alchemy--logo--container" />;

	const onSubmit = async () => {
		const { user, connection, domain } = userData;
		const myParams = `?connection=${connection}&userid=${user}`;
		const uri = await getUrl(domain, "recoverPassword", myParams);
		const myHeaders = new Headers();
		myHeaders.append("Accept", "*/*");
		myHeaders.append("Content-Type", `application/json`);
		try {
			const res = await fetch(uri, {
				headers: myHeaders,
				method: "POST",
			});
			if (res.status === 200) {
				const response = await res.json();
				//
			} else {
				console.log("ERORR recoverPassword status code");
			}
		} catch (error) {
			console.log("ERORR recoverPassword: ", error);
		}
	};

	useEffect(() => {
		try {
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const sessionParam = urlParams.get("session");
			const decoded = Base64.decode(sessionParam);
			const sessionData = JSON.parse(decoded);
			//console.log("sessionData:", sessionData);
			const { user, connections } = sessionData;
			const myConnection = connections[0].connection;
			const url_be_menu = connections[0].url_be_menu;
			setUserData({
				user: user,
				connection: myConnection,
				domain: url_be_menu,
			});
		} catch (error) {
			console.log("RecoverPassword-useEffect-error:", error);
		}
	}, []);

	return (
		<div className="register--screen--main--container">
			<Card header={header} style={{ width: "400px", zIndex: "100" }}>
				<FormTitle title="¿Olvidó su contraseña?" />
				<form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
					<div className="p-field p-col-12 p-md-6">
						<Controller
							name="email"
							control={control}
							rules={{
								required: "required",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message:
										"Invalid email address. E.g. example@email.com",
								},
							}}
							render={({ field, fieldState }) => (
								<FormInputText
									field={field}
									fieldState={fieldState}
									classNameIcon={"pi pi-envelope"}
									placeholder={`Ingrese su usuario o correo electrónico`} //placeholder={t(`recovery.title`)}
									errorMessage={""} //errorMessage={t("recovery.errors.email")}
								/>
							)}
						/>
					</div>

					<div
						onClick={() => history.goBack()}
						className="recover--screen--main--container--inputs--link"
					>
						Volver
					</div>

					<div
						className="p-field p-col-12 p-md-6"
						style={{ margin: "28px 10px" }}
					>
						<Button
							disabled={false}
							type="submit"
							label="Enviar"
							className="p-button-info p-button-raised"
						/>
					</div>
				</form>
			</Card>
		</div>
	);
};
