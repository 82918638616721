/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unreachable */
import React, { useEffect, useState, useRef } from "react";
import jwt_decode from "jwt-decode";
import profileImg from "../../assets/icons/profile.png";
import alchemyIcon from "../../assets/icons/alchemy.png";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { ListBox } from "primereact/listbox";
import { HeaderIcon } from "../headerIcon/headerIcon";
import { startLogout } from "../../actions/auth";
import { OverlayPanel } from "primereact/overlaypanel";
import { hamburgerStyle } from "../../constants/icons";
import { useSelector, useDispatch } from "react-redux";
import { getUrl } from "../../actions/getUrl";
import {
	getNotifications,
	openProfileModal,
	toggleSidebar,
	fetchNotificationsCount,
} from "../../actions/ui";
import "./header.css";
import { LanguageImage } from "../languageImage/languageImage";
import EspIcon from "../../assets/icons/sp-flag.png"; 
import EngIcon from "../../assets/icons/uk-flag.png";
//agregado 02-2025
import { Badge } from "primereact/badge";
import axios from "axios";
import loadConfig from "../../helpers/configLoader";

export const Header = () => {
	const { t, i18n } = useTranslation("global");
	const { openSidebar } = useSelector(state => state.ui)
	const dispatch = useDispatch();
	const token = localStorage.getItem("token");
	const { conn, company } = jwt_decode(token); 
	const user = JSON.parse(localStorage.getItem("user-session"));
	const [profile, setProfile] = useState({
		src: user.img_profile ? user.img_profile : profileImg,
		errored: false,
	});

	//////////////////////////////////////////////////// Esto es para las notificaciones
	const [countnotif, setCountNotif] = useState(0);
	const [intervalTime, setIntervalTime] = useState(3600000); // 1 hora en milisegundos por defecto

    // Función para obtener Cantidad de notificaciones desde la API
    /*const fetchNotificationsCount = async () => {
        try {
			const token = localStorage.getItem("token");
			const { user, conn } = jwt_decode(token);
			const myParams = `?connection=${conn}&userid=${user}&count=1`;
			const { url_be_menu } = JSON.parse( localStorage.getItem("user-data-url") );
			const url_count_notifications = await getUrl( url_be_menu, "userNotifications", myParams);
            const response = await axios.get(url_count_notifications, {
				headers: {
				  Authorization: `Bearer ${token}`
				}
			});
            setCountNotif(response.data.notifications); 
        } catch (error) {
            console.error("Error obteniendo notificaciones:", error);
        }
    };
	*/

    useEffect(() => {
        loadConfig().then(config => {
            if (config.notificationMinutesInterval) {
                setIntervalTime(config.notificationMinutesInterval);
            }
        });
    }, []);

	// Esto es para que cada tanto se actualice automaticamente la cantidad de notificaciones
	useEffect(() => {
        const interval = setInterval(() => {
			fetchNotificationsCount();
        }, intervalTime);

        return () => clearInterval(interval);
    }, [intervalTime]); 

	// Esto es para que al hacer click en el boton actualice la cantidad y los datos
	const accionesNotificaciones = () => {
		return async (dispatch) => {
			const resultado = await dispatch(fetchNotificationsCount());
      		setCountNotif(resultado);
		  	dispatch(getNotifications());
		};
	  };

	/////////////////////////////////////////// Termina notificaciones

	const username = JSON.parse(localStorage.getItem("user-session")) || {
		username: "...",
	};

	const [selectedCountries, setSelectedCountries] = useState(null);

	const hamburgerIcon = {
		icon: "fas fa-bars",
		onClickIcon: () => dispatch(toggleSidebar()),
	};

	const items = [
		{
			name: `${t("options.profile")}`,
			icon: "fas fa-user-circle",
			command: () => {
				if (openSidebar) {
					dispatch(toggleSidebar())
				}
				dispatch(openProfileModal())
			},
		},
		{
			name: `${t("options.company")}: ${company}`,
			icon: "fas fa-building",
			command: () => console.log(company),
		},
		{
			name: `${t("options.connection")}: ${conn}`,
			icon: "fas fa-info-circle",
			command: () => console.log(2),
		},
		{
			name: `${t("options.logout")}`,
			icon: "fas fa-chevron-circle-left",
			command: () => dispatch(startLogout()),
		},
	];

	const op2 = useRef(null);

	const optionTemplate = (option) => (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "flex-start",
			}}
			onClick={() => option.command()}
		>
			<span className={`${option.icon} icon--element--menu`} />
			<div style={{ color: "#000", paddingLeft: "15px" }}>{option.name}</div>
		</div>
	);

	const onErrorProfile = () => {
		if (!profile.errored) {
			setProfile({
				src: profileImg,
				errored: true,
			});
		}
	};

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user-session"));
		const profile = user.picture;

		const countnotif = user.notifications;
		setCountNotif(countnotif);

		setProfile({
			src: profile || profileImg,
			errored: false,
		});
		try {
			const language = localStorage.getItem("language");
			i18n.changeLanguage(language);
		} catch (error) {
			i18n.changeLanguage(i18n.language);
		}
	}, []);

	return (
		<div className="header--main--container">
			<div className="header--main--container-index">
				<div className="header--main--container--toogle">
					<HeaderIcon element={hamburgerIcon} iconStyle={hamburgerStyle} />
				</div>
				<div className="header--main--container--logo">
					<div className="header--main--container--logo--image">
						<img src={alchemyIcon} alt="..." />
					</div>
				</div>
				<div className="header--main--container--icons">
					<div style={{ cursor: "pointer", position: "relative"  }}>
						{countnotif > 0 && (
							<Badge
							value={countnotif}
							severity="danger"
							className="p-mr-2"
							style={{
								position: "absolute",
								top: "-5px",
								right: "30px",
							}}
							></Badge>
						)}
						<Button
							type="button"
							icon="fas fa-bell classIconBell"
							tooltip={t("options.notif")} 
							tooltipOptions={{ position: "bottom" }}
							//onClick={() => dispatch(getNotifications())}
							onClick={() => dispatch(accionesNotificaciones())}
							aria-haspopup
							aria-controls="overlay_panel"
							className="select-product-button"
						/>
					</div>
				</div>
				<div style={{ height: "30px", width: "40px" }}>
					{profile && (
						<img
							id="navbar-user-picture-element"
							src={profile.src}
							onError={onErrorProfile}
							style={{ height: "100%", width: "100%", objectFit: "cover" }}
							alt=""
						/>
					)}
				</div>
				<div style={{ cursor: "pointer" }}>
					<Button
						label={<div id="label--btn--name">{username.username}</div>}
						className="btn-profile-menu"
						icon="fas fa-chevron-down"
						iconPos="right"						
						tooltip={t("options.profile")}//"Perfil" //agregado 10/11/2021 falta ver según idioma!
						tooltipOptions={{ position: "bottom" }} //agregado 10/11/2021
						onClick={(e) => op2.current.toggle(e)}
					/>

					<OverlayPanel
						ref={op2}
						id="overlay_panel"
						className="overlaypanel-options"
					>
						<ListBox
							value={selectedCountries}
							options={items}
							onChange={(e) => setSelectedCountries(e.value)}
							optionLabel="name"
							itemTemplate={optionTemplate}
							style={{ width: "15rem" }}
							listStyle={{ maxHeight: "250px" }}
						/>

						<div className="header--languages--container">
							<LanguageImage
								src={EspIcon}
								onClick={() => {
									i18n.changeLanguage("es");
									localStorage.setItem("language", "es");
								}}
							/>
							<LanguageImage
								src={EngIcon}
								onClick={() => {
									i18n.changeLanguage("en");
									localStorage.setItem("language", "en");
								}}
							/>
						</div>
					</OverlayPanel>
				</div>
			</div>
		</div>
	);
};
