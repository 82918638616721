import { types } from "../types/types";
import { initialStateUi } from "./initialStateUi";

export const uiReducer = (state = initialStateUi, action) => {
	switch (action.type) {
		/* ****************************************** */
		case types.REQUEST_LAST_USED_LOADING:
			return {
				...state,
				loadingLastUsed: true,
			};
		case types.REQUEST_LAST_USED_SUCCESS:
			return {
				...state,
				loadingLastUsed: false,
				lastUsed: action.payload,
			};
		case types.REQUEST_LAST_USED_FAILURE:
			return {
				...state,
				loadingLastUsed: false,
				errorLastUsed: true,
			};
		case types.SET_DATA_LAST_USED:
			return {
				...state,
				loadingLastUsed: false,
				errorLastUsed: true,
				lastUsed: action.payload,
			};
		/* ****************************************** */
		case types.REQUEST_FAVORITES_LOADING:
			return {
				...state,
				loadingFavorites: true,
			};
		case types.REQUEST_FAVORITES_SUCCESS:
			return {
				...state,
				loadingFavorites: false,
				favorites: action.payload,
				// activeMenu: 2,
			};
		case types.REQUEST_FAVORITES_FAILURE:
			return {
				...state,
				loadingFavorites: false,
				errorFavorites: true,
				favorites: null,
				activeMenu: 0,
			};
		case types.UPDATE_FAVORITES_LIST:
			return {
				...state,
				favorites: action.payload,
			};
		// case "REMOVE_FAVORITE_IDPROCESS":
		// 	return {
		// 		...state,
		// 		favorites: state.favorites.filter((x, i) => i !== action.payload),
		// 	};
		/* ****************************************** */
		case types.UPDATE_MENU_BY_FAVORITES_SELECT:
			return {
				...state,
				menuData: action.payload,
			};
		/* ****************************************** */
		case types.SELECTED_NODE_PROCESSES_LOADING:
			return {
				...state,
				loadingProcessesNode: true,
			};
		case types.SELECTED_NODE_PROCESSES_SUCCESS:
			return {
				...state,
				loadingProcessesNode: false,
				processesNode: action.payload,
			};
		case types.SELECTED_NODE_PROCESSES_FAILURE:
			return {
				...state,
				loadingProcessesNode: false,
				errorProcessesNode: true,
			};
		/* ****************************************** */
		case types.OPEN_PROFILE_MODAL:
			return {
				...state,
				openModalProfile: !state.openModalProfile,
			};
		/* ****************************************** */
		case types.OPEN_NOTIFICATION_MODAL:
			return {
				...state,
				openModalNotification: !state.openModalNotification,
				selectedNotification: action.payload,
			};
		/* ****************************************** */
		case types.CLOSE_TAB:
			return {
                ...state,
                openTabs: state.openTabs.filter(tab => tab.id !== action.payload.id) // Filtra y remueve el tab cerrado
            };
		/* ****************************************** */
		case types.HIDE_TREE_MENU_DATA:
			return {
				...state,
				hideTreeMenuData: !state.hideTreeMenuData,
			};
		/* ****************************************** */
		case types.REQUEST_ICONS_PROCESS_LOADING:
			return {
				...state,
				loadingIconsProcess: true,
			};
		case types.REQUEST_ICONS_PROCESS_SUCCESS:
			return {
				...state,
				loadingIconsProcess: false,
				iconsProcess: action.payload,
			};
		case types.REQUEST_ICONS_PROCESS_FAILURE:
			return {
				...state,
				loadingIconsProcess: false,
				errorIconsProcess: true,
			};
		/* ****************************************** */
		case types.SELECT_NEW_FOR_TAB:
			return {
				...state,
				newSelectedNodeTab: action.payload,
			};
		/* ****************************************** */
		case types.REQUEST_MENU_DATA_LOADING:
			return {
				...state,
				loadingMenuData: true,
			};
		case types.REQUEST_MENU_DATA_SUCCESS:
			return {
				...state,
				loadingMenuData: false,
				menuData: action.payload,
				activeMenu: 0,
			};
		case types.REQUEST_MENU_DATA_FAILURE:
			return {
				...state,
				loadingMenuData: false,
				errorMenuData: true,
			};
		/* ****************************************** */
		case types.TOGGLE_SIDEBAR:
			return {
				...state,
				openSidebar: !state.openSidebar,
			};
		/* ****************************************** */
		case types.BALANCE_OPEN_MODAL:
			return {
				...state,
				balanceOpenModal: !state.balanceOpenModal,
				selectedBalanceItem: action.payload,
			};
		/* ****************************************** */
		case types.REQUEST_NOTIFICATIONS_LOADING:
			return {
				...state,
				loadingNotifications: true,
				errorNotifications: false,
			};
		case types.REQUEST_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				loadingNotifications: false,
				notifications: action.payload,
			};
		case types.REQUEST_NOTIFICATIONS_FAILURE:
			return {
				...state,
				loadingNotifications: false,
				errorNotifications: true,
			};
		/* ****************************************** */
		case types.SET_NOTIFICATIONS_COUNT:
			return {
				...state,
				countnotif: action.payload,
			};
		/* ****************************************** */
		case types.uiSetError:
			return {
				...state,
				msgError: action.payload,
			};

		case types.uiRemoveError:
			return {
				...state,
				msgError: null,
			};

		case types.uiStartLoading:
			return {
				...state,
				loading: true,
			};

		case types.uiFinishLoading:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
};
