import jwt_decode from "jwt-decode";
import { setHeadersToken } from "../api/api";
import { types } from "../types/types";
import { getUrl, getDomain } from "./getUrl";
import { NotificationTable } from "../components/notificationTable/notificationTable";
import React, { useState } from "react";

export const setUserConnections = (data) => ({
	type: types.SET_USER_CONNECTIONS,
	payload: data,
});

export const setError = (err) => ({
	type: types.uiSetError,
	payload: err,
});

export const removeError = () => ({
	type: types.uiRemoveError,
});

export const startLoading = () => ({
	type: types.uiStartLoading,
});

export const finishLoading = () => ({
	type: types.uiFinishLoading,
});

export const toggleSidebar = () => ({
	type: types.TOGGLE_SIDEBAR,
});

export const openProfileModal = () => ({
	type: types.OPEN_PROFILE_MODAL,
});

export const openNotificationModal = (data) => ({
	type: types.OPEN_NOTIFICATION_MODAL,
	payload: data,
});

export const hideTreeMenuData = () => ({
	type: types.HIDE_TREE_MENU_DATA,
});

export const getNotifications = () => {
	return async (dispatch) => {
		dispatch({ type: types.REQUEST_NOTIFICATIONS_LOADING });
		try {
			const token = localStorage.getItem("token");
			const { user, conn } = jwt_decode(token); 
			const myParams = `?connection=${conn}&userid=${user}`;
			const userUrls = JSON.parse(localStorage.getItem("user-data-url"));
			const domain = userUrls.url_be_menu;
			const uri = await getUrl( domain, "userNotifications", myParams);
			const requestOptions = setHeadersToken();
			const resp = await fetch(uri, requestOptions);
			if (resp.status === 200) {
				const response_notif = await resp.json();
				const jsonData = response_notif.nodes.data;
				dispatch({
					type: types.REQUEST_NOTIFICATIONS_SUCCESS,
					payload: {
						id: "idNotifications",
						icon: "fas fa-exclamation-triangle",
						style: "overflow: auto; height: 100%",
						title: (
							<> Notificaciones
							  <i className="fas fa-bell" style={{ marginRight: "8px" }}></i> 
							</>
						  ),
						panelComponent: <NotificationTable jsonData={jsonData} />,
					},
				});
			} else {
				dispatch({ type: types.REQUEST_NOTIFICATIONS_FAILURE });
			}
		} catch (error) {
			dispatch({ type: types.REQUEST_NOTIFICATIONS_FAILURE });
		}
	};
};

// Función para obtener Cantidad de notificaciones que se muestra en el header
export const fetchNotificationsCount = () => {
	return async (dispatch) => {
		try {
			const token = localStorage.getItem("token");
			const myHeaders = setHeadersToken();
			const { user, conn } = jwt_decode(token);
			const myParams = `?connection=${conn}&userid=${user}&count=1`;
			const { url_be_menu } = JSON.parse( localStorage.getItem("user-data-url") );
			const url_count_notifications = await getUrl( url_be_menu, "userNotifications", myParams);
			const resp = await fetch(url_count_notifications, myHeaders);
			/*const response = await axios.get(url_count_notifications, {
				headers: {
				Authorization: `Bearer ${token}`
				}
			});*/
			const response = await resp.json();
			dispatch({ 
					type: types.SET_NOTIFICATIONS_COUNT,
					payload: response.notifications,
			 });
			 return response.notifications;
		} catch (error) {
			console.error("Error obteniendo notificaciones:", error);
		}
	}
};

export const selectNewNodeForTab = (data) => ({
	type: types.SELECT_NEW_FOR_TAB,
	payload: data,
});

export const getMenuData = () => {
	return async (dispatch) => {
		dispatch({ type: types.REQUEST_MENU_DATA_LOADING });
		try {
			const token = localStorage.getItem("token");
			const { user, conn } = jwt_decode(token); //cambio id/db user/conn 01/03/2022
			const myParams = `?connection=${conn}&userid=${user}`;
			const userUrls = JSON.parse(localStorage.getItem("user-data-url"));
			const domain = userUrls.url_be_menu; //cambiado 29/11/2021
			const uri = await getUrl(domain, "userMenu", myParams);
			const requestOptions = setHeadersToken();
			const res = await fetch(uri, requestOptions);
			if (res.status === 200) {
				const response = await res.json();
				dispatch({
					type: types.REQUEST_MENU_DATA_SUCCESS,
					payload: response,
				});
			} else {
				console.log('Error cargando menu');
				dispatch({ type: types.REQUEST_MENU_DATA_FAILURE });
			}
		} catch (error) {
			dispatch({ type: types.REQUEST_MENU_DATA_FAILURE });
		}
	};
};

export const updateMenuBySelectFavorite = (newList) => ({
	type: types.UPDATE_MENU_BY_FAVORITES_SELECT,
	payload: newList,
});

export const getProcessesFavorites = (userId, connection) => {
	return async (dispatch) => {
		dispatch({ type: types.REQUEST_FAVORITES_LOADING });
		try {
			const myHeaders = setHeadersToken();
			const myParams = `?connection=${connection}&userid=${userId}`;
			const userUrls = JSON.parse(localStorage.getItem("user-data-url"));
			const domain = userUrls.url_be_menu; //cambiado 29/11/2021
			const uri = await getUrl(domain, "getFavorites", myParams);
			const res = await fetch(uri, myHeaders);
			if (res.status === 200) {
				const response = await res.json();
				if (response.error == 0) {
					dispatch({
						type: types.REQUEST_FAVORITES_SUCCESS,
						payload: response.favorites,
					});
				} else {
					dispatch({ type: types.REQUEST_FAVORITES_FAILURE });
				}
			} else {
				dispatch({ type: types.REQUEST_FAVORITES_FAILURE });
			}
		} catch (error) {
			// dispatch({ type: types.REQUEST_FAVORITES_FAILURE });
		}
	};
};

export const getLastUsedProcesses = (userId, connection) => {
	return async (dispatch) => {
		dispatch({ type: types.REQUEST_LAST_USED_LOADING });
		try {
			//si userid y connection vienen vacios, los obtengo de localstorage
			if (userId == undefined && connection == undefined){
				const token = localStorage.getItem("token");
				const { user, conn } = jwt_decode(token);
				userId = user;
				connection = conn;
			}
			const myHeaders = setHeadersToken();
			const myParams = `?connection=${connection}&userid=${userId}`;
			const userUrls = JSON.parse(localStorage.getItem("user-data-url"));
			const domain = userUrls.url_be_menu; //cambiado be mp 30/11/2021
			const uri = await getUrl(domain, "getLastUsed", myParams);
			const res = await fetch(uri, myHeaders);
			if (res.status === 200) {
				const response = await res.json();
				if (response.error == 0) {
					dispatch({
						type: types.REQUEST_LAST_USED_SUCCESS,
						payload: response.ultusados,
					});
					const lastUsedStr = JSON.stringify(response.ultusados);
					sessionStorage.setItem("alchemy_last_used", lastUsedStr);
				} else {
					dispatch({ type: types.REQUEST_LAST_USED_FAILURE });
				}
			} else {
				dispatch({ type: types.REQUEST_LAST_USED_FAILURE });
			}
		} catch (error) {
			dispatch({ type: types.REQUEST_LAST_USED_FAILURE });
		}
	};
};

export const getLastUsedFromSessionStorage = () => {
	const sessionStoragelastUsed = JSON.parse(
		sessionStorage.getItem("alchemy_last_used"),
	);
	return {
		type: types.SET_DATA_LAST_USED,
		payload: sessionStoragelastUsed,
	};
};

export const updateFavoriteList = (data) => ({
	type: types.UPDATE_FAVORITES_LIST,
	payload: data,
});
